<template>
    <div class="mt-5">

        <el-row type="flex" justify="space-between">

            <el-col :span="6">
                <el-input v-model="search" placeholder="Buscar Pastelerías" clearable suffix-icon="el-icon-search"/>
            </el-col>

            <el-col :span="6" class="d-flex justify-content-end">
                <router-link :to="{ name: 'cakeshops-new'}">
                    <el-button type="success">
                        Nueva Pastelería
                    </el-button>
                </router-link>
            </el-col>

        </el-row>

        <el-row class="mt-4 px-5 py-2 bg-white border border-gray rounded">
            <el-col>
                <el-table
                    class="table m-0"
                    ref="cakeshopTable"
                    @row-click="goToCakeshopDetail"
                    :row-class-name="'cursor-pointer'"
                    :data="cakeshops.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))">
                    <el-table-column
                        label="Nombre"
                        prop="name"
                        width="400">

                        <template slot-scope="data">
                            <span class="table-cell-name">{{data.row.name}}</span>
                        </template>

                    </el-table-column>
                    <el-table-column
                        label="Ciudad"
                        prop="city.name"
                        :filters="citiesFilter"
                        :filter-method="filterCities">
                    </el-table-column>
                    <el-table-column
                        label="Creaciones"
                        prop="cakesCount">
                    </el-table-column>
                    <el-table-column
                        label="Pedidos"
                        prop="ordersCount">
                    </el-table-column>
                    <el-table-column
                        label="Estado"
                        prop="status"
                        min-width="100"
                        :filters="[{text: 'Publicada', value: 'PUBLISHED'}, {text: 'Inactiva', value: 'INACTIVE'}, {text: 'Borrador', value: 'DRAFT'}]"
                        :filter-method="filterHandler">

                        <template slot-scope="data">
                            <publish-status style="font-size: 12px" :status="data.row.status"/>
                        </template>

                    </el-table-column>

                    <el-table-column align="right" width="50">
                        <template slot="header">
                            <svg-icon
                                style="width: 1.5em; height: 1.5em;"
                                icon-class="reset"
                                class="cursor-pointer"
                                @click="resetFilters"/>
                        </template>


                        <template slot-scope="data">
                            <el-tooltip content="Sin direcciones habilitadas" effect="light">
                                <svg-icon v-if="!data.row.hasAddressesEnabled" style="width: 1.5em; height: 1.5em;" icon-class="no-address-icon"/>
                            </el-tooltip>
                        </template>
                    </el-table-column>

                </el-table>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import PublishStatus from "../../components/PublishStatus/index";

    export default {
        name: "CakeshopList",
        components: {
            PublishStatus,
        },
        data() {
            return {
                search: ""
            }
        },
        created() {
            this.$store.dispatch('loadCakeshops');
        },
        methods: {
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            filterCities(value, row) {
                return row.city.name === value;
            },
            resetFilters() {
                this.$refs.cakeshopTable.clearFilter();
            },
            goToCakeshopDetail(row) {
                this.$router.push({ name: 'cakeshop-profile', params: { uuid: row.uuid, cakeshop: row  } })
            }
        },
        computed: {
            ...mapGetters([
                'cakeshops'
            ]),
            citiesFilter() {
                let cities = this.cakeshops.map(item => item.city.name);
                cities = cities.filter((value, index, self) => self.indexOf(value) === index);
                return cities.map(item => {
                    return {text: item, value: item}
                })
            }
        }
    }
</script>
