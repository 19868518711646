<template>
    <div class="d-flex flex-row align-items-center">
        <template v-if="status === 'PUBLISHED'">
            <img src="@/assets/images/status_published.png" class="mr-2" alt="Icono para el estado publicado">
            <span>Publicada</span>
        </template>
        <template v-else-if="status === 'INACTIVE'">
            <img src="@/assets/images/status_inactive.png" class="mr-2" alt="Icono para el estado no publicada">
            <span>No Publicada</span>
        </template>
        <template v-else-if="status === 'DRAFT'">
            <img src="@/assets/images/status_draft.png" class="mr-2" alt="Icono para el estado en borrador">
            <span>Borrador</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: "PublishStatus",
        props: {
            status: {
                type: String,
                required: true
            },
        },
    }
</script>

<style scoped>
    img {
        width: 14px;
        height: 14px;
    }
</style>
